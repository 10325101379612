// ...
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://273c91332d55e16f84ceb266362d1dd1@o4507492234297344.ingest.de.sentry.io/4507497296494672',
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
