import React from 'react';

const logo = require('../../images/logo-blue.svg');

function Full() {
  return (
        <div className='center'>
        <div><img width={200} height={175} src={logo} alt="NETOPIA Payments" /></div>
            <div className="loadingio-spinner-eclipse-y0y932fm6xl">
                <div className="ldio-kd3ovpwgubp">
                    <div></div>
                </div>
            </div>
        </div>
  );
}

function Simple() {
  return (
          <div className='center'>
              <div className="loadingio-spinner-eclipse-y0y932fm6xl">
                  <div className="ldio-kd3ovpwgubp">
                      <div></div>
                  </div>
              </div>
          </div>
  );
}
export { Full, Simple };
