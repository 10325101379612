/* eslint-disable */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

// Import all the third party stuff

// Import date provider

// Load the favicon and the .htaccess file
// import '!file-loader?name=[name].[ext]!./images/favicon.ico';
// import 'file-loader?name=.htaccess!./.htaccess'

import { createRoot } from 'react-dom/client';
import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './app.css';
import './sentry';
// Import Boot app
import Boot from './containers/Boot/loadable';
// Import Language Provider

// Create redux store with history
const MOUNT_NODE = createRoot(
  document.getElementById("app")
);



// Default query config for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error.status === 404) return false;
        if (failureCount < 2) return false;
        return false;
      },
    },
  },
});

const isLocalHost = document.URL.includes('localhost');

const render = () => {
  MOUNT_NODE.render(
    <>
      <QueryClientProvider client={queryClient}>
        <Boot></Boot>

        {isLocalHost && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    root.unmount();
    render();
  });
}

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise((resolve) => {
//     resolve(import('intl'))
//   })
//     .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
//     .then(() => render(translationMessages))
//     .catch((err) => {
//       throw err
//     })
// } else {
//   render(translationMessages)
// }

let DEFAULT_LOCALE;
try {
  if (!window) {
    DEFAULT_LOCALE = 'en';
  } else {
    DEFAULT_LOCALE = 'ro';
  }
} catch (err) {
  DEFAULT_LOCALE = 'en';
}

render(DEFAULT_LOCALE);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
