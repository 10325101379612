import React, { lazy, Suspense } from 'react';

const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);
  function myElement(props) {
    return <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>;
  }
  myElement.displayName = 'NTP_LazyComponent';
  return myElement;
};

export default loadable;
